<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import Master from "@/apis/Master";
import Swal from "sweetalert2";
/**
 * Orders Component
 */
export default {
  components: {
    Layout,
    PageHeader
  },
  data() {
    return {
      title: "ROLE MANAGEMENT",
      items: [
        {
          text: "DASHBOARD",
          href: "/"
        },
        {
          text: "ROLE MANAGEMENT",
          active: true
        }
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 50,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "id",
      sortDesc: false,
      selected:"true",
      fields: [
        { key: "slNo", sortable: false, label: "SL NO" ,thStyle: { color: "black", "font-size":"16px"} },
        { key: "roleName", sortable: true, label: "ROLE", thStyle: { color: "black", "font-size":"16px"}  },
        { key: "pageAccess", sortable: false, label: "PAGE ACCESS", thStyle: { color: "black", "font-size":"16px"}  },
        { key: "action", label: "ACTION", thStyle: { color: "black", "font-size":"16px"}  }
      ],
      rolesData: [],
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.rolesData.length;
    }
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.rolesData.length;

    Master.adminRoleList().then((res) => {
      console.log(res)
      this.rolesData = res.data.data;
    })


  },

  methods: {
    /**
     * Edit Color Modal
     */

    async editPage(data){
      localStorage.setItem("roleId",data);
    },
    async singleDelete(data){
      var userId = JSON.parse(localStorage.getItem('user'))
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Yes, delete it!"
      }).then(result => {
        if (result.value) {
          Master.adminRoleDelete(
              {
                ids: [data],
                userId: userId.id,
                trash: 0
              }).then(res => {
            console.log(res)
            Swal.fire("Deleted!", "Your data has been deleted.", "success");
            // this.successMessage = res.data.message;
            Master.adminRoleList().then((res) => {
              console.log(res)
              this.rolesData = res.data.data;
            })
          })
        }
      });
    },

    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    }
  }
};
</script>

<template>
  <Layout>

    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body" style="text-transform: uppercase !important;">
            <div class="btn-toolbar">
              <router-link to="/add-role"> <a href="javascript:void(0);" class="btn btn-primary mb-2 mr-1"  @click="showmodal = true">
                <i class="mdi mdi-plus mr-2"></i> Add
              </a></router-link>
            </div>

            <b-tabs nav-class="nav-tabs-custom">
              <div class="row mt-4">
                <div class="col-sm-12 col-md-6">
                  <div id="tickets-table_length" class="dataTables_length">
                    <label class="d-inline-flex align-items-center">
                      Show&nbsp;
                      <b-form-select v-model="perPage" size="sm" :options="pageOptions"></b-form-select>&nbsp;entries
                    </label>
                  </div>
                </div>
                <!-- Search -->
                <div class="col-sm-12 col-md-6">
                  <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                    <label class="d-inline-flex align-items-center">
                      Search:
                      <b-form-input
                          v-model="filter"
                          type="search"
                          class="form-control form-control-sm ml-2"
                      ></b-form-input>
                    </label>
                  </div>
                </div>
                <!-- End search -->
              </div>
              <div class="table-responsive">
                <b-table
                    class="table-centered"
                    :items="rolesData"
                    :fields="fields"
                    responsive="sm"
                    :per-page="perPage"
                    :current-page="currentPage"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    :filter="filter"
                    :filter-included-fields="filterOn"
                    @filtered="onFiltered"
                    show-empty
                    striped
                    sort-icon-left

                >
                  <template v-slot:cell(slNo)="row">
                    <p class="badge font-size-12">{{row.index+1}}</p>
                  </template>
                  <template v-slot:cell(pageAccess)="row">
                    <p class="badge font-size-12">{{ row.item.rolePages.map(entry => entry.pageName).join(', ')}}</p>
                  </template>
                  <template v-slot:cell(roleName)="row">
                    <p class="badge font-size-12">
                      {{row.item.roleName}}
                    </p>
                  </template>
                  <template v-slot:cell(status)="row">
                    <div v-if="row.value == 1"
                         class="badge font-size-12 badge-soft-success"
                    >ACTIVE</div>
                    <div v-if="row.value == 0"
                         class="badge font-size-12 badge-soft-danger"
                    >IN-ACTIVE</div>
                  </template>
                  <template v-slot:cell(action) = "data">
                    <router-link to="/edit-role"><a
                        href="javascript:void(0);"
                        class="mr-3 text-primary"
                        @click="editPage(data.item.roleId)"
                        v-b-tooltip.hover
                        title="Edit"
                    >
                      <i class="mdi mdi-pencil font-size-18"></i>
                    </a></router-link>
                    <a
                        href="javascript:void(0);"
                        class="text-danger"
                        @click="singleDelete(data.item.roleId)"
                        v-b-tooltip.hover
                        title="Delete"
                    >
                      <i class="mdi mdi-trash-can font-size-18"></i>
                    </a>
                  </template>
                </b-table>
              </div>
              <div class="row">
                <div class="col">
                  <div class="dataTables_paginate paging_simple_numbers float-right">
                    <ul class="pagination pagination-rounded mb-0">
                      <!-- pagination -->
                      <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"></b-pagination>
                    </ul>
                  </div>
                </div>
              </div>
              <!--              </b-tab>-->
            </b-tabs>
          </div>
        </div>
      </div>
    </div>

  </Layout>
</template>